<div *ngIf="display">
    <header class="header header-6 theme-container" fxHide fxShow.gt-md>

        <div class="header-middle">
            <div class="containerCss">
                <div fxFlex="10" style="margin-top: 15px;" fxLayoutAlign="center center" *ngIf="data.showLeftMenu==1">
                    <calsoft-left-menu fxLayoutAlign="center center" [categoryArray]="item"></calsoft-left-menu>
                </div>

                <div fxFlex="10" *ngIf="data.showLeftMenu!=1">
                    
                </div>
               
                <div fxFlex="15">
                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                </div>
                <div fxFlex="4"></div>

                <div *ngIf="url=='/account/information'" fxFlex="60" fxLayoutAlign="start center">
                    <div>
                        <calsoft-Menu fxLayoutAlign="center center" style="z-index: 1000;" [data]="data" [type]="'one'"
                            [categoryArray]="item"></calsoft-Menu>
                    </div>
                </div>

                <div *ngIf="url!='/account/information'" fxFlex="40" fxLayoutAlign="start center">
                    <div class="search-bar" fxLayout="row" fxLayoutAlign="start center" fxFlex="400px">
                        <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                            [searchbarborderRadius]="'15px'"></calsoft-search>
                    </div>

                </div>

                <div fxFlex="12" fxLayoutAlign="start center">

                    <div fxLayoutAlign="center center" *ngIf="data.disableLoginHeader==0">
                        <mat-icon class="icon-css" *ngIf="!userLoggedIn" (click)="SignIn('login')">person</mat-icon>
                    </div>
                    <div *ngIf="data.disableLoginHeader==0">
                    <calsoft-HeaderUserProfileDropdown fxLayoutAlign="center center" [data]="data"  *ngIf="userLoggedIn">
                    </calsoft-HeaderUserProfileDropdown>
                    </div>
                </div>

                <div fxLayoutAlign="end center" fxFlex="10"
                    *ngIf="url !== '/cart' && url !== '/checkout' && url !=='/checkout/type' ">

                    <div *ngIf="data.disableWishlistHeader==0">
                        <button  fxLayoutAlign="center center" (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist'">
                            <mat-icon style="color: black;">favorite_border</mat-icon>

                        </button>
                    </div>

                  
                    <calsoft-cart-icon [data]="data"  [CartName]="'Cart'"  fxFlex></calsoft-cart-icon>

                </div>

                <div *ngIf="url!='/account/information'" fxLayoutAlign="end center" fxFlex="8">
                    <a style="font-size: 16px;" routerLink="/aboutus">About Us</a>
                </div>

                <div *ngIf="data.enableWhatsappChat==1" fxLayoutAlign="end center" fxFlex="5">
                    <div style="cursor: pointer;" *ngIf="url!='/account/information'" (click)="navigateWhatsapp()">
                        <img style="height: 30px;" src="assets/icons/whatsapp.svg" alt="whatsapp">
                    </div>
                </div>



                <div fxFlex="3"></div>
            </div>
        </div>

    </header>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">
    
        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header8>
    </div>
    <div *ngIf="mobileHeaderType==8">    
        <calsoft-mobile-header9 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header9>
    </div>
</div>