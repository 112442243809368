<div id="box" *ngIf="init">
  <div [style.background-attachment]="'fixed'" [style.background-repeat]="'no-repeat'" (scroll)="onScrollEvent($event)"
    class="app-theme bgimage" [style.backgroundImage]="'url(' + serverPath5 + homePageBackgroundImgSrc + ')'"
    style="background: white;" [ngClass]="theme">

    <mat-sidenav-container [ngClass]="homePageBackgroundEnable  =='1' ? 'wrap-container' :'' "
      style="background: white;" [ngStyle]="{'height': heightSet ? '100vh' : 'auto'}" class="page-container">
      <mat-sidenav #sidenav [(opened)]="calsoftService.sidenavOpen" class="sidebar-area" mode="over">
        <mat-nav-list>
          <calsoft-side-bar-menu [data]="finalData" [sideMenuItem]="categoryArray"></calsoft-side-bar-menu>
        </mat-nav-list>
      </mat-sidenav>

      <mat-sidenav-content *ngIf="!calsoftService.sidenavOpen">

        <div>
          <div *ngIf="!url.includes('/course-detail/')">
            <div *ngIf="type !== 'six' && type!=='twentyfour' && type !== 'five' && type !=='seven' && type !=='eight' && type !=='nine' && type !=='ten'
           && type !=='twelve' && type !=='thirteen' && type !=='fifteen' && type !=='sixteen' && type !=='twenty'">

              <div *ngIf="headerEnabled&&!headerBannerRotateEnable"
                [style.background-color]="finalData.headerBannerBackgroundColors"
                [style.backgroundImage]="'url(' + serverPath2 + headerLogoSrc + ')'"
                class="promo-top w-100 h-auto text-center theme-container">
                <div class="copy-container">
                  <div class="flex-container">
                    <h4 [style.color]="finalData.headerBannerTextColour"
                      class="font-weight-normal text-uppercase text-white m-0">
                      {{finalData.headerNotificationContent}}
                    </h4> &nbsp;
                    <h4 *ngIf="finalData.headerBannerCopyEnable" [style.color]="finalData.headerBannerTextColour"
                      class="font-weight-normal text-uppercase text-white m-0">
                      {{finalData.headerBannerCopyCoupon}}
                    </h4>&nbsp; &nbsp;
                    <button *ngIf="finalData.headerBannerCopyEnable"
                      (click)="copyToClipboard(finalData.headerBannerCopyCoupon)">
                      <mat-icon>content_copy</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="headerEnabled&&headerBannerRotateEnable"
                [style.backgroundImage]="'url(' + serverPath2 + headerLogoSrc + ')'"
                class="promo-top w-100 h-auto text-center theme-container">
                <div class="copy-container" [ngClass]="finalData.headerBannerRotateEnable==1 ? 'marquee-text' : ''">
                  <div class="flex-container">
                    <h4 [style.color]="finalData.headerBannerTextColour"
                      class="font-weight-normal text-uppercase text-white m-0">
                      {{finalData.headerNotificationContent}}
                    </h4> &nbsp;
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="!url.includes('/course-detail/') && !this.loginPage ">
            <calsoft-header [data]="finalData" [languageSelection]="languageSelection" *ngIf="type == 'one'"
              [item]="categoryArray"> </calsoft-header>
            <HeaderTwo [data]="finalData" [languageSelection]="languageSelection" *ngIf="type == 'two' "
              [item]="categoryArray"></HeaderTwo>

            <calsoft-header-three [data]="finalData" [languageSelection]="languageSelection" *ngIf="type == 'three'"
              [item]="categoryArray"></calsoft-header-three>

            <HeaderFour [data]="finalData" [languageSelection]="languageSelection" *ngIf="type == 'four'"
              [item]="categoryArray"></HeaderFour>

            <calsoft-header-five *ngIf="type == 'five'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header-five>

            <calsoft-header-six [data]="finalData" [languageSelection]="languageSelection" [item]="categoryArray"
              *ngIf="type == 'six'"></calsoft-header-six>


            <calsoft-header-seven [data]="finalData" [languageSelection]="languageSelection" [item]="categoryArray"
              *ngIf="type == 'seven'"></calsoft-header-seven>

            <calsoft-header-eight [data]="finalData" [languageSelection]="languageSelection" [item]="categoryArray"
              *ngIf="type == 'eight'"></calsoft-header-eight>

            <calsoft-headernine [data]="finalData" [languageSelection]="languageSelection" [item]="categoryArray"
              *ngIf="type == 'nine'"></calsoft-headernine>

            <calsoft-header10 *ngIf="type == 'ten'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray"></calsoft-header10>

            <calsoft-header11 *ngIf="type == 'eleven'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray"></calsoft-header11>

            <calsoft-header12 *ngIf="type == 'twelve'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray"></calsoft-header12>

            <calsoft-header13 *ngIf="type == 'thirteen'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray"></calsoft-header13>

            <calsoft-header14 *ngIf="type == 'fourteen'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray"></calsoft-header14>

            <calsoft-header15 *ngIf="type == 'fifteen'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray"></calsoft-header15>

            <calsoft-header16 *ngIf="type == 'sixteen'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray"></calsoft-header16>

            <calsoft-header17 *ngIf="type == 'seventeen'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray" [containerClass]="containerClass"></calsoft-header17>


            <calsoft-header18 *ngIf="type == 'eighteen'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray"></calsoft-header18>

            <calsoft-header19 *ngIf="type == 'nineteen'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header19>

            <calsoft-header20 *ngIf="type == 'twenty'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">

            </calsoft-header20>

            <calsoft-header21 *ngIf="type == 'twentyOne'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header21>

            <calsoft-header22 *ngIf="type == 'twentytwo'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header22>

            <calsoft-header23 *ngIf="type == 'twentythree'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header23>


            <calsoft-header24 *ngIf="type == 'twentyfour'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header24>

            <calsoft-header25 *ngIf="type == 'twentyfive'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header25>

            <calsoft-header26 *ngIf="type == 'twentysix'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header26>

            <calsoft-header27 *ngIf="type == 'twentyseven'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header27>

            <calsoft-header28 *ngIf="type == 'twentyeight'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header28>

            <calsoft-header29 *ngIf="type == 'twentynine'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header29>

            <calsoft-header30 *ngIf="type == 'thirty'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header30>

            <calsoft-header31 *ngIf="type == 'thirtyone'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header31>

            <calsoft-header32 *ngIf="type == 'thirtytwo'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header32>

            <calsoft-header33 *ngIf="type == 'thirtythree'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header33>

            <calsoft-header34 *ngIf="type == 'thirtyfour'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header34>

            <calsoft-header35 *ngIf="type == 'thirtyfive'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray" [scrollvalue]="scroll_value">
            </calsoft-header35>

            <calsoft-header36 *ngIf="type == 'thirtysix'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray" [scrollvalue]="scroll_value">

            </calsoft-header36>

            <calsoft-header37 *ngIf="type == 'thirtyseven'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">

            </calsoft-header37>

            <calsoft-header38 *ngIf="type == 'thirtyeight'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">

            </calsoft-header38>

            <calsoft-header39 *ngIf="type == 'thirtynine'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">

            </calsoft-header39>

            <calsoft-header40 *ngIf="type == 'fourty'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header40>

            <calsoft-header41 *ngIf="type == 'fourtyone'" [data]="finalData" [languageSelection]="languageSelection"
              [item]="categoryArray">
            </calsoft-header41>

            <calsoft-header42 *ngIf="type == 'fourtytwo'" [data]="finalData" [languageSelection]="languageSelection"
            [item]="categoryArray">
          </calsoft-header42>

          <calsoft-header43 *ngIf="type == 'fourtythree'" [data]="finalData" [languageSelection]="languageSelection"
          [item]="categoryArray">
        </calsoft-header43>

        <calsoft-header44 *ngIf="type == 'fourtyfour'" [data]="finalData" [languageSelection]="languageSelection"
        [item]="categoryArray">
      </calsoft-header44>

      <calsoft-header45 *ngIf="type == 'fourtyfive'" [data]="finalData" [languageSelection]="languageSelection"
      [item]="categoryArray">

      </calsoft-header45>

          </div>



          <ng-container>

            <div class="theme-container main" [ngStyle.gt-md]="{
              'padding-top':type == 'fourtythree' ? '0px' : type == 'thirtyfour' ? '120px' :
                             type == 'thirtyfive' ? '90px' : ''
            }">


            <!-- <div class="theme-container main" [ngStyle.gt-md]="{
              'padding-top': type == 'thirtyfour' ? '120px' :
                             type == 'thirtyfive' ? '90px' :
                             type == 'thirtyone' ? '50px' : ''
            }"> -->
              <div [ngStyle]="{'display':hideBreadcrump ? 'none' : '' }" class="container" *ngIf="!breadCrumpDisable && !this.loginPage ">

                <calsoft-breadcrumb fxHide fxShow.gt-sm></calsoft-breadcrumb>
              </div>



              <div>
                <div class="main12" style="margin-top: 2px;"
                  (touchstart)=" finalData.mobileLeftRightScroll==1?onTouchStart($event):''"
                  (touchend)=" finalData.mobileLeftRightScroll==1?onTouchEnd($event):''" mat-sidenav-container>


                  <router-outlet></router-outlet>

                  <calsoft-newsletter-ads *ngIf="showPopup"></calsoft-newsletter-ads>

                </div>
              </div>
            </div>

          </ng-container>

          <div class="visiblity12" *ngIf="displayFooterOnMOb&&!defaultFooter">


            <div fxHide fxShow.gt-sm>
              &nbsp;


            </div>

          </div>





          <div *ngIf="enableFooterWebsite==0 && !this.loginPage ">

            <div [ngClass]="noPaddingFooter==0 ? 'margin-t10' : ''" *ngIf="!display && type != 'twentyseven'">
              <calsoft-footer *ngIf="footerLayout == 1" [data]="finalData" fxHide fxShow.md fxShow.lg fxShow.gt-lg>

              </calsoft-footer>
              <calsoft-footer4 *ngIf="footerLayout == 5" [data]="finalData" fxHide fxShow.md fxShow.lg
                fxShow.gt-lg></calsoft-footer4>
              <calsoft-footer3 *ngIf="footerLayout == 4" [data]="finalData" fxHide fxShow.md fxShow.lg
                fxShow.gt-lg></calsoft-footer3>
              <calsoft-footer2 *ngIf="footerLayout == 3" [data]="finalData" fxHide fxShow.md fxShow.lg
                fxShow.gt-lg></calsoft-footer2>
              <calsoft-footer1 *ngIf="footerLayout == 2" [data]="finalData" fxHide fxShow.md fxShow.lg
                fxShow.gt-lg></calsoft-footer1>
              <calsoft-footer5 *ngIf="footerLayout == 6" [data]="finalData" fxHide fxShow.md fxShow.lg
                fxShow.gt-lg></calsoft-footer5>
              <calsoft-footer6 *ngIf="footerLayout == 7" [data]="finalData" fxHide fxShow.md fxShow.lg
                fxShow.gt-lg></calsoft-footer6>

              <calsoft-footer7 *ngIf="footerLayout == 8" [data]="finalData" fxHide fxShow.md fxShow.lg
                fxShow.gt-lg></calsoft-footer7>
              <calsoft-footer8 *ngIf="footerLayout == 9" [data]="finalData" fxHide fxShow.md fxShow.lg
                fxShow.gt-lg></calsoft-footer8>
              <calsoft-footer9 *ngIf="footerLayout == 10" [data]="finalData" fxHide fxShow.md fxShow.lg
                fxShow.gt-lg></calsoft-footer9>
              <calsoft-footer10 *ngIf="footerLayout == 11" [data]="finalData" fxHide fxShow.md fxShow.lg
                fxShow.gt-lg></calsoft-footer10>
            </div>
          </div>


          <div [ngClass]="noPaddingFooter==0 ? 'margin-t10' : ''" *ngIf="display && type != 'twentyseven'  && !this.loginPage">
            <calsoft-footer *ngIf="footerLayout == 1" [data]="finalData"> </calsoft-footer>
            <calsoft-footer4 *ngIf="footerLayout == 5" [data]="finalData"></calsoft-footer4>
            <calsoft-footer3 *ngIf="footerLayout == 4" [data]="finalData"></calsoft-footer3>
            <calsoft-footer2 *ngIf="footerLayout == 3" [data]="finalData"></calsoft-footer2>
            <calsoft-footer1 *ngIf="footerLayout == 2" [data]="finalData"></calsoft-footer1>
            <calsoft-footer5 *ngIf="footerLayout == 6" [data]="finalData"></calsoft-footer5>
            <calsoft-footer6 *ngIf="footerLayout == 7" [data]="finalData"></calsoft-footer6>
            <calsoft-footer7 *ngIf="footerLayout == 8" [data]="finalData"></calsoft-footer7>
            <calsoft-footer8 *ngIf="footerLayout == 9" [data]="finalData"></calsoft-footer8>
            <calsoft-footer9 *ngIf="footerLayout == 10" [data]="finalData"></calsoft-footer9>
            <calsoft-footer10 *ngIf="footerLayout == 11" [data]="finalData"></calsoft-footer10>
          </div>

          <div *ngIf="enableFooterWebsite==1">
            <div [ngClass]="noPaddingFooter==0 ? 'margin-t10' : ''">
            </div>
          </div>

          <div *ngIf="footerScrollTextEnable && !this.loginPage">
            <div *ngIf="url=='/home'">
              <br>
              <br>
              <br>
              <br *ngIf="!displayFooterOnMOb">
              <br *ngIf="!displayFooterOnMOb">

            </div>
            <calsoft-footer-header [data]="finalData"></calsoft-footer-header>
          </div>


          <!-- <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" >
            <div class="icon-box" *ngIf="footerScrollTextEnable">
              <calsoft-footer-header [data]="finalData"></calsoft-footer-header>
            </div>
          </div> -->

          <div *ngIf="displayFooterOnMOb&&url!='/cart'
          && !url.includes('/product/') && !this.loginPage
           && url!='/checkout' && url!='/checkout/type'" fxHide fxShow.lt-lg>
            <br>
            <br>
            <br>
          </div>

          <span *ngIf="!url.includes('/product/') && !this.loginPage">
            <ng-container *ngIf="displayFooterOnMOb">
              <footer fxHide.lg fxHide.gt-lg
                *ngIf="url!='/cart' && url!='/checkout' && url!='/checkout/type'"
                class="_fijon mat-elevation-z6 sticky mat-elevation-z7">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div *ngFor="let item of footerMenu;let i=index" fxFlex fxLayout="column"
                    fxLayoutAlign="center center">
                    <button [color]="item.url==currentUrl?'primary':null" (click)="account(i,item.secured)"
                      [class]="item.url==currentUrl?'material-icons':'material-icons-outlined'" mat-icon-button>
                      <mat-icon class="primary-icon" *ngIf="item.url!='/cart'">{{item.icon}}</mat-icon>
                      <mat-icon class="primary-icon" *ngIf="item.url=='/cart'"
                        [matBadge]="userLoggedIn&&cartServices.cartCount!=0?cartServices.cartCount:''"
                        matBadgePosition="above after" matBadgeColor="accent">
                        shopping_cart
                      </mat-icon>
                    </button>
                    <small>{{item.name}}</small>
                  </div>

                </div>
              </footer>



            </ng-container>

          </span>

        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

  </div>
  <!-- </div> -->
  <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="tap-top top-cls primary"
    (click)="scrollToTop()">
    <mat-icon>arrow_upward</mat-icon>
  </div>

  <div fxLayout="row" *ngIf="enableWhatsappChat==1&&!meetingUrl" fxLayoutAlign="center center" style="background: #E7F3EC;"
    class="whatsapp top-cls" cdkDrag  (click)="navigate()">
    <img style="height: 30px;" src="assets/icons/whatsapp.svg" alt="whatsapp">
  </div>


  <div fxLayout="row" *ngIf="displayDeliveryIcon==1" fxLayoutAlign="center center" style="background: #E7F3EC;"
    class=" delivery top-cls">
    <img style="height: 64px;" (click)="navigateTo(finalData.deliveryIconNavigationLink)"
      onerror="this.src='assets/icons/Medical Delivery-01.svg';" [src]="serverPath6 + deliveryIconImage"
      [alt]="deliveryIconImage">
  </div>

</div>