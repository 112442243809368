<mat-toolbar class="mobileheader">
    <mat-toolbar-row [style.margin]="mobileHeaderLogoGap" [style.height]="mobileHeaderHeight" fxLayout="row"
        fxLayoutAlign="space-between center">

        <div fxFlex fxLayoutAlign="start center">

            <img [src]="serverPath3+mobileLogoSrc" fxLayoutAlign="start start"  onerror="this.src='';" />
        </div>

        <div fxFlex="20" fxLayoutAlign="center center">
            <button [style.color]="data.headerIconColour" class="responsive-toggle" mat-icon-button
                (click)="toggleSidebar()">
                <i style="font-size: 25px;" [style.color]="data.headerIconColour" class="material-icons ">menu</i>
            </button>
        </div>

    </mat-toolbar-row>

</mat-toolbar>


<div class="mobileheader calsoft-fixed-header " [style.height]="mobileHeaderHeight" >

    <div class="container">
        <div [style.height]="mobileHeaderHeight" fxLayout="row"
        fxLayoutAlign="space-between center">

      
        <div fxFlex fxLayoutAlign="start center">

            <img [src]="serverPath3+mobileLogoSrc" fxLayoutAlign="start start"  onerror="this.src='';" />
        </div>
        <div  fxFlex fxLayoutAlign="end end">
            <button [style.color]="data.headerIconColour" class="responsive-toggle" mat-icon-button
                (click)="toggleSidebar()">
                <i style="font-size: 25px;" [style.color]="data.headerIconColour" class="material-icons ">menu</i>
            </button>
        </div>

    </div>

    </div>

</div>

