<div *ngIf="display"> 
<header class="header banner theme-container" fxHide  fxShow.gt-md>

    <div class="notification" style="background-image: url(assets/images/notification-back.jpg)"
    *ngIf="showTopNotice && !isNotificationClosed()">
    <div class="notify-content">

        <h3>FREE SHIPPING FOR ALL ORDERS OVER  {{data.freeShippingAppliedAmount | currencyConvert}}</h3>

        <div fxLayout="row" fxLayoutAlign="flex-end">
            <button (click)="closeTopNotice()" mat-icon-button>
                <mat-icon style="color:white; padding-left: 12%;">close</mat-icon>
            </button>
        </div>
        
    </div>
</div>

    <div class="header-top">
        <div [class]="containerClass">
            <div class="header-left">
                <div class="header-dropdown">
                    <div class="top-menu">
                        <div *ngIf="languageSelection==1">
                            <calsoft-language></calsoft-language>
                        </div>
                    </div>
                </div>
            </div>

            <div class="header-right header-dropdown">

                <ul class="top-menu">
                    <li>
                        <ul>
                            <li *ngIf="data.showContactNumber==1" (click)="callPhone()" style="cursor: pointer;"><a>Call: {{data.storePhoneNumber}}</a></li>
                            <li><a routerLink="/aboutus">About Us</a></li>
                            <li *ngIf="data.showContactPage==1"><a routerLink="/contact-us">Contact Us</a></li>
                            <li *ngIf="data.disableLoginHeader==0" class="login" style="cursor: pointer;"><a *ngIf="!userLoggedIn" (click)="SignIn('login')">
                                    <i class="icon-user"></i>Login</a></li>
                                    <div *ngIf="data.disableLoginHeader==0">
                            <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center"  
                                *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                                </div>
                        </ul>
                        
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <mat-toolbar-row fxLayoutAlign="start center" style="height: auto;">

        <div fxFlex="5">

        </div>
        <div fxLayout="row" fxFlex="20" fxLayoutGap="10px">
            <section class="padding-10">

                <calsoft-logo  [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </section>
        </div>

        <div fxLayout="row" fxLayoutGap="10px" fxFlex="65">

            <calsoft-Menu [color]="false"  [data]="data"  [type]="'one'" style="color: #000000; z-index: 1000;" class="menu" [categoryArray]="item"></calsoft-Menu>

        </div>

        <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
            <div *ngIf="data.disableSearchInHeader==0">

            <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                <mat-icon style=" cursor: pointer;" fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                    (click)="showSearchBar()">search</mat-icon>
            </div>


            <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="350px">
                <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                    [searchbarborderRadius]="'15px'"></calsoft-search>
            </div>

            </div>

        </div>


        <div fxLayoutAlign="end center" fxFlex="10" *ngIf="url !== '/cart' && url !== '/checkout' && url !=='/checkout/type' ">

            <div *ngIf="data.disableWishlistHeader==0">
            <button (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist'">
                <mat-icon>favorite_border</mat-icon>

            </button>
            </div>

            <calsoft-cart-icon [data]="data"  fxFlex ></calsoft-cart-icon>

        </div>
    </mat-toolbar-row>

    <mat-toolbar fxHide fxShow.gt-md class="calsoft-fixed-header" fxLayout="row"
    fxLayoutAlign="space-between center" style="background-color: black; height: auto; z-index: 1000;">

    <mat-toolbar-row class="theme-container" fxLayoutAlign="space-between center" style="height: auto;" >

    <div fxLayout="row" fxLayoutGap="10px" fxFlex="80">

        <calsoft-Menu [color]="true" fxLayoutAlign="center center" style="z-index: 1000;" [data]="data" [type]="'type'"
            [categoryArray]="item"></calsoft-Menu>

    </div>

    <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">

        <div *ngIf="data.disableSearchInHeader==0">
        <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
            <mat-icon style=" cursor: pointer; color:white"  fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                (click)="showSearchBar()">search</mat-icon>
        </div>


        <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
            fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="300px">
            <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                [searchbarborderRadius]="'15px'"></calsoft-search>
        </div>
</div>

    </div>

    <div fxLayoutAlign="end center" *ngIf="url != '/cart'">

        <button style="color: white;" (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist'">
            <mat-icon>favorite_border</mat-icon>

        </button>

        <calsoft-cart-icon [data]="data"   ></calsoft-cart-icon>

    </div>
    
    <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
        <div style="color: #ffffff" fxLayout="column">
            <small>Hello, Sign in</small>
            <span>Account & Lists</span>
        </div>
    </button>
    <div *ngIf="data.disableLoginHeader==0">
    <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
    </calsoft-HeaderUserProfileDropdown>
    </div>

    <div fxFlex="3">

    </div>

    </mat-toolbar-row>
</mat-toolbar>
</header>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">
    
        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header8>
    </div>
    <div *ngIf="mobileHeaderType==8">    
        <calsoft-mobile-header9 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header9>
    </div>
</div>