<div *ngIf="display">
    <mat-toolbar fxHide fxShow.gt-md class="header-v2 primary" fxLayoutAlign="space-between center">
        <mat-toolbar-row style="height: 100%;border-bottom: 1px solid white;" class="theme-container" fxLayoutAlign="space-around center">


            <div fxLayout="row" fxLayoutGap="10px">


                <section>
                    <calsoft-logo [sitename]="data.siteName" [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                </section>
                
            </div>

            <div fxLayoutAlign="center center" class="select-city" fxFlex="none" fxLayoutGap="5px">
                <!-- <section>
                    <calsoft-flag></calsoft-flag>
                </section> -->
                <button [style.color]="data.categoryColor"  (click)="openCitySelectionDialog()" class="slc">
                    {{ selectedCity ? selectedCity.name : 'Select City' }}
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
            </div>

            <div *ngIf="data.disableSearchInHeader==0" class="search-bar" fxLayoutGap="10px" fxHide fxShow.gt-sm
                fxShow.gt-sm fxLayout="row" fxLayoutAlign="center center" fxFlex fxFlex.gt-sm="400px">

                <calsoft-search  fxLayoutAlign="center center" [searchbarborderRadius]="'20px'"></calsoft-search>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'"
                    [categoryArray]="list1"></calsoft-Menu>
            </div>

            <div fxHide fxShow.gt-sm class="list-flex user-tool">

                <div *ngIf="data.disableLoginHeader==0">
                    <div *ngIf="!userLoggedIn" (click)="SignIn('login')" fxLayoutAlign="start center">
                        <button [style.color]="data.headerIconColour" mat-flat-button class="primary"
                            style="border-radius: 20px; font-size: 12px;"
                            *ngIf="!userLoggedIn"><span><mat-icon
                                    style="font-size: 18px;">person_outline</mat-icon></span> LOGIN/SIGNUP
                        </button>
                    </div>
                    <div *ngIf="data.disableLoginHeader==0">

                    <calsoft-HeaderUserProfileDropdown [data]="data" *ngIf="userLoggedIn">
                    </calsoft-HeaderUserProfileDropdown>
                    </div>

                </div>
                
                <div fxLayout="column" (click)="callPhone()" class="phone-no"
                    style="cursor: pointer; margin-left: 36px;">
                    <span [style.color]="data.categoryColor" class="call-text">Call us at</span>
                    <span [style.color]="data.categoryColor" class="call-text">{{data.storePhoneNumber}}</span>
                </div>



                
            </div>

        </mat-toolbar-row>

    </mat-toolbar>
    <mat-toolbar fxHide fxShow.gt-md>



        <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center"
            style="
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);padding: 0px;color: #000000;height: -webkit-fill-available"
            class="header-v2-bottom main-menu-wrap theme-container primary">

            <div class="container">
                <calsoft-Menu [data]="data" [type]="'one'" style="z-index: 1000;"
                    [categoryArray]="list2"></calsoft-Menu>
            </div>


        </mat-toolbar-row>
    </mat-toolbar>


    <mat-toolbar style="z-index: 1000;" fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header primary "
        fxLayout="row">
        <mat-toolbar-row style="height: 100%;" class="theme-container" fxLayoutAlign="space-between center">
            <section>
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </section>

            <div fxLayoutAlign="start center" class="select-city" fxFlex="none">
                <button [style.border-color]="data.categoryColor" [style.color]="data.categoryColor"  (click)="openCitySelectionDialog()" class="slc">
                    {{ selectedCity ? selectedCity.name : 'Select City' }}
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
            </div>

            <section *ngIf="data.disableSearchInHeader==0">
                <div class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center"
                    fxFlex fxFlex.gt-sm="400px">
                    <calsoft-search  fxLayoutAlign="center center" [searchbarborderRadius]="'20px'"></calsoft-search>
                </div>
            </section>
            <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
                <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'"
                [categoryArray]="list1"></calsoft-Menu>

                <div *ngIf="data.disableLoginHeader==0">
                    <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
                        <div [style.color]="data.headerIconColour" fxLayout="column">
                            <small>Hello, Sign in</small>
                            <span>Account & Lists</span>
                        </div>
                    </button>
                </div>
                <div *ngIf="data.disableLoginHeader==0">
                <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser"
                    *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>
                </div>
               
                <div fxLayout="column" (click)="callPhone()" class="phone-no"
                style="cursor: pointer; margin-left: 36px;">
                <span [style.color]="data.categoryColor" class="call-text">Call us at</span>
                <span [style.color]="data.categoryColor" class="call-text">{{data.storePhoneNumber}}</span>
            </div>
            
            </section>
        </mat-toolbar-row>
    </mat-toolbar>

</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">
    
        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header8>
    </div>
    <div *ngIf="mobileHeaderType==8">    
        <calsoft-mobile-header9 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header9>
    </div>
</div>