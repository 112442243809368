
import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CommonService } from 'src/app/services/common.service';
import { SideNavService } from 'src/app/services/side-nav.service';
import { SideBarMenuPopupComponent } from '../side-bar-menu-popup.component';


@Component({
  selector: 'calsoft-side-bar-child-popup',
  templateUrl: './side-bar-child-popup.component.html',
  styleUrls: ['./side-bar-child-popup.component.scss']
})
export class SideBarChildPopupComponent implements OnInit {
  @Input() menuItems:any=[];
  @Input() menuParentId=2;
  parentMenu:Array<any>;
  menuId: string;

  @Input() depth: number = 0;

  constructor(private sidenavMenuService:SideNavService,    
    private dialog: MatDialog, 
      public calsoftService: CalsoftService, private renderer: Renderer2,private commonService:CommonService, ) { }

  ngOnInit() {
    this.parentMenu = this.menuItems;
  }

  onClick(menuId,parentId,menu){
    this.sidenavMenuService.toggleMenuItem(menuId,parentId,menu);
    this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);    
  }


  toggleMenuItem(menuId: string) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);
  
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        this.renderer.removeClass(menuItem, 'expanded');
      } else {
        subMenu.classList.add('show');
        this.renderer.addClass(menuItem, 'expanded');
        this.menuId=menuId;
      }
    }
  }
  isExpanded(menuId: string): any {
    // Check if the menu item is expanded
    if(this.sidenavMenuService.expandedItems.findIndex(item => item === menuId)>=0) {
      return true;
    }else{
      return false;
    }
  }

  goToCategory(menu) {

    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);
   this.dialog.closeAll();
  

 }

}
