<div fxFlex="column" fxLayoutGap="20px" [style.height]="item.height" style="padding-top: 10px;" [ngStyle.lt-md]="{'height': item.heightMob}"  >

    <div fxLayout="row" fxLayoutAlign="start stretch" class="slide-container">

        <div fxFlex="30" fxFlex.xs="40" fxLayout="column" class="slide-info">
            <div class="slide-no"> <span>{{ currentSlideIndex + 1 }}</span>
            </div>
            <div class="slide-name">
                <span>{{ currentSlide?.customField6 }}</span>
            </div>
        </div>


        <div fxFlex="70" fxFlex.xs="60" class="slide-description-container" (scroll)="onScroll($event)" fxLayoutGap="10px">
            <!-- <div class="title" style="text-align: center;margin-bottom: 50px;"> 
                <h1>Services</h1>
        
            </div> -->
            <div *ngFor="let slide of img;let i = index" class="slide-description">

                <ng-container *ngIf="item.customField15 != '0'">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center"
                        style="height: 100%; width: 100%; margin-bottom: 40px;">
                        <div fxFlex="auto" style="text-align: center;">
                           
                            <img [src]="serverPath + slide.customField8"
                                [style.height]="item.customField9 || 'auto'"
                                [style.width]="item.customField10 || 'auto'" class="floating-image" alt="image" />
                        </div>
                    </div>
                </ng-container>
                <div>
                    <span [innerHTML]="slide.customField7" [style.font-size]="item.customField4"
                        [ngStyle.lt-md]="{ 'font-size': item.customField5 }"></span>
                </div>
                <ng-container *ngIf="item.customField15 == '0'">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center"
                        style="height: 100%; width: 100%; margin-top: 40px;">
                    
                        <div fxFlex="auto" style="text-align: center;">
                            <img [src]="serverPath + slide.customField8"
                                [style.height]="item.customField9 || 'auto'"
                                [style.width]="item.customField10 || 'auto'" class="floating-image" alt="image" />
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
</div>