<mat-toolbar class="mobileheader">
    <mat-toolbar-row [style.margin]="mobileHeaderLogoGap" [style.height]="mobileHeaderHeight" fxLayout="row"
        fxLayoutAlign="space-between center">

        <div fxFlex fxLayoutAlign="start center">
            <img [src]="serverPath3+mobileLogoSrc" fxLayoutAlign="start start"  onerror="this.src='';" (click)="goToHome()"/>
        </div>

        <div fxFlex="20" fxLayoutAlign="center center">
            <button [style.color]="datas.headerIconColour" class="responsive-toggle" mat-icon-button
                (click)="cancel()">
                <i style="font-size: 25px;" [style.color]="datas.headerIconColour" class="material-icons ">close</i>
            </button>
        </div>

    </mat-toolbar-row>

</mat-toolbar> 
 
 <!-- <mat-toolbar style="height: 70px;background-color: white;padding-top: 15px;" >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
        <div>
            <div fxFlex="75" class="padding-10" >
             <img [src]="serverPath3+mobileLogoSrc" onerror="this.src='';" style="width: 100%;" (click)="goToHome()"/>
             </div>
             <div fxFlex="10"></div>
             <div fxFlex="15" fxLayoutAlign="center center">
                 <button [style.color]="datas.headerIconColour"  class="responsive-toggle"
                 mat-icon-button (click)="cancel()">
                 <i style="font-size: 30px;" [style.color]="datas.headerIconColour" class="material-icons ">close</i>
                 </button>               
             </div>
         </div>

        </div>
</mat-toolbar> -->
<mat-dialog-content>
<div *ngFor="let menu of parentMenu" class="menu-item">

    <a *ngIf="menu.subcategories.length==0" mat-button class="categorycss"
        fxLayout="row" fxLayoutAlign="space-between center"
         
       (click)="goToCategory(menu)" [id]="'menu-item-'+menu.categoryId">
        <span class="menu-title" style="font-family: var(--website-font-family);">{{ menu.categoryName  }}</span>
        
    </a>
    
    <a *ngIf="menu.subcategories.length>0" mat-button class="categorycss"
        fxLayout="row" fxLayoutAlign="space-between center"       
        (click)="onClick(menu.categoryId,menu.parentId,menu.subcategories)" [id]="'menu-item-'+menu.categoryId">
        <span class="menu-title font"  >{{ menu.categoryName  }}</span>
               <mat-icon *ngIf="isExpanded(menu.categoryId,menu.subcategories)" class="menu-expand-icon transition-2 menu-expand-icon1" >keyboard_arrow_down</mat-icon>

        <mat-icon  *ngIf="!isExpanded(menu.categoryId,menu.subcategories)" class="menu-expand-icon transition-2" >keyboard_arrow_down</mat-icon>
    </a>

    <div *ngIf="menu.subcategories.length>0" class="sub-menu" [id]="'sub-menu-'+menu.categoryId" [style.marginLeft.px]="depth < 2 ? 15 : 0" >
        
        <calsoft-side-bar-child-popup  [menuItems]="menu.subcategories" [menuParentId]="menu.categoryId"  [depth]="depth + 1"></calsoft-side-bar-child-popup>
    </div>
    <hr style="border: 1px solid #eee" />
</div>
</mat-dialog-content>