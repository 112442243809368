import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';

import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Constant } from 'src/app/constants/constant';
import { CategoryService } from 'src/app/services/category.service';
import { SideBarMenuPopupComponent } from 'src/app/theme/components/menu/menu/side-bar-menu/side-bar-menu-popup/side-bar-menu-popup.component';
@Component({
  selector: 'calsoft-mobile-header9',
  templateUrl: './mobile-header9.component.html',
  styleUrls: ['./mobile-header9.component.scss']
})
export class MobileHeader9Component implements OnInit{

  @Input() url: any;
  @Input() userLoggedIn: any;
  @Input() color: any;
  categoryArray: any = [];
  referer: string;

  //logoPositionMobile:boolean = false;
  mobileHeaderHeight: any;
  mobileHeaderLogoGap: any;
  layout: any = 0;
  headerIconColour: any;
  @Input() data: any;
  public serverPath = environment.commonImageApi + "logo/";

  public serverPath3 = environment.commonImageApi + "mobileImage/";

  mobileLogoSrc: any;

  mobileLogoEnable: any = 0;
  disableCartIconInHeader: any = 0;

  disableLoginHeader: any = 0;
  disableWishlistHeader: any = 0;
  contentDiv:boolean;
  constructor(
    private router: Router,
    public cartServices: CartService,
    private dialog: MatDialog,
    private userService: UserService,
    private categoryService: CategoryService,
    private location: Location,
    public calsoftService: CalsoftService,) { 
      this.contentDiv = false;
    }

    display: boolean;
  ngOnInit(): void {

    if (window.innerWidth < 1281) {
      this.display = false;
    }else{
      this.display=true
    }

    this.getCategories();
    this.referer=Constant.REFERER;
    
    if (this.data != undefined) {
      if (this.data['logoPositionMobile'] != undefined) {
        this.layout = this.data['logoPositionMobile'];
      }

      if (this.data['headerIconColour'] != undefined) {
        this.headerIconColour = this.data['headerIconColour'];
      }

      if (this.data['mobileLogoSrc'] != undefined) {
        this.mobileLogoSrc = this.data['mobileLogoSrc'];
      }

      if (this.data['mobileLogoEnable'] != undefined) {
        this.mobileLogoEnable = this.data['mobileLogoEnable'];
      }

      if (this.data['mobileHeaderHeight'] != undefined) {
        this.mobileHeaderHeight = this.data['mobileHeaderHeight'];
      }


      if (this.data['mobileHeaderLogoGap'] != undefined) {
        this.mobileHeaderLogoGap = this.data['mobileHeaderLogoGap'];
      }


      if (this.data['disableCartIconInHeader'] != undefined) {
        this.disableCartIconInHeader = this.data['disableCartIconInHeader'];
      }


      if (this.data['disableLoginHeader'] != undefined) {
        this.disableLoginHeader = this.data['disableLoginHeader'];
      }

      if (this.data['disableWishlistHeader'] != undefined) {
        this.disableWishlistHeader = this.data['disableWishlistHeader'];
      }



    }



    this.userLogged();

  }
  page1: any;
  public toggleSidebarz() {
   // this.calsoftService.sidenavOpen = !this.calsoftService.sidenavOpen;
  this.contentDiv = !this.contentDiv;
  }

  cart() {
    let basicAuthHeaderString = this.userService.getAuthenticatedToken();
    let username = this.userService.getAuthenticatedUser();

    if (basicAuthHeaderString && username) {
      this.router.navigate(['/cart'])

    }
    else {
      this.page1 = "cart";
      this.loginModel();

      // this.router.navigate(['/cart'])

    }

  }


  loginModel() {
    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(CommonSignInComponent, {
        width: '790px',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });

    }
    else {
      let dialogRef = this.dialog.open(CommonSignInComponent, {
        minWidth: '100vw', height: '100vh',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });
    }

  }
  back() {

    if (this.router.url.includes('/order/')) {
      this.router.navigate(['/orders'])
    } else {
     // this.router.navigate(['/'])
       this.location.back();
    }

  }


  SignIn() {
    // this.router.navigate(['/sign-in']);
    this.loginModel();
  }


  userLogged() {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    let basicAuthHeaderString = this.userService.getAuthenticatedToken();
    let username = this.userService.getAuthenticatedUser();

    if (basicAuthHeaderString && username) {

      this.userLoggedIn = true;
      this.cartServices.getCount();

    }
    else {

      this.userLoggedIn = false;
    }
  }


  navigateTo(route: string) {
    this.router.navigate([route]);
  }


  getCategories() {
    this.categoryService.categoryList().subscribe(
       result => {
          this.categoryArray = result['data'];
       },
       error => {
       }
    )
 }

 toggleSidebar() {
  let dialogRef = this.dialog.open(SideBarMenuPopupComponent, {
    minWidth: '100vw', height: '100vh',
    backdropClass: 'no-backdrop', 
    panelClass: 'custom-dialog', 
    data: { data: this.categoryArray, datas:this.data },
  });
 
  dialogRef.afterClosed().subscribe((result) => {
  });
}

}
