import { Component, OnInit, Input, Renderer2, Inject } from '@angular/core';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CommonService } from 'src/app/services/common.service';
import { SideNavService } from 'src/app/services/side-nav.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Constant } from 'src/app/constants/constant';
import { Router } from '@angular/router'; 

@Component({
  selector: 'calsoft-side-bar-menu-popup',
  templateUrl: './side-bar-menu-popup.component.html',
  styleUrls: ['./side-bar-menu-popup.component.scss']
})
export class SideBarMenuPopupComponent implements OnInit{

  @Input() menuItems:any=[];
  @Input() menuParentId=2;
  parentMenu:Array<any>;
  menuId: string;
  data:any;
  datas:any;
  @Input() depth: number = 0;

  referer: string;

  //logoPositionMobile:boolean = false;
  mobileHeaderHeight: any;
  mobileHeaderLogoGap: any;
  layout: any = 0;
  headerIconColour: any;
  public serverPath = environment.commonImageApi + "logo/";

  public serverPath3 = environment.commonImageApi + "mobileImage/";

  mobileLogoSrc: any;

  mobileLogoEnable: any = 0;
  disableCartIconInHeader: any = 0;

  disableLoginHeader: any = 0;
  disableWishlistHeader: any = 0;


  constructor(private sidenavMenuService:SideNavService, public calsoftService: CalsoftService, private renderer: Renderer2,private commonService:CommonService,
    private dialog: MatDialog,    private dialogRef: MatDialogRef<SideBarMenuPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data,private router: Router
   ) {
    console.log(data);
    this.parentMenu = data.data;
    this.datas = data.datas;
    }

  ngOnInit() {

    this.referer=Constant.REFERER;
    
    if (this.datas != undefined) {
      if (this.datas['logoPositionMobile'] != undefined) {
        this.layout = this.datas['logoPositionMobile'];
      }

      if (this.datas['headerIconColour'] != undefined) {
        this.headerIconColour = this.datas['headerIconColour'];
      }

      if (this.datas['mobileLogoSrc'] != undefined) {
        this.mobileLogoSrc = this.datas['mobileLogoSrc'];
      }

      if (this.datas['mobileLogoEnable'] != undefined) {
        this.mobileLogoEnable = this.datas['mobileLogoEnable'];
      }

      if (this.datas['mobileHeaderHeight'] != undefined) {
        this.mobileHeaderHeight = this.datas['mobileHeaderHeight'];
      }


      if (this.datas['mobileHeaderLogoGap'] != undefined) {
        this.mobileHeaderLogoGap = this.datas['mobileHeaderLogoGap'];
      }


      if (this.datas['disableCartIconInHeader'] != undefined) {
        this.disableCartIconInHeader = this.datas['disableCartIconInHeader'];
      }


      if (this.datas['disableLoginHeader'] != undefined) {
        this.disableLoginHeader = this.datas['disableLoginHeader'];
      }

      if (this.datas['disableWishlistHeader'] != undefined) {
        this.disableWishlistHeader = this.datas['disableWishlistHeader'];
      }
    }

  }

  onClick(menuId,parentId,menu){
    this.sidenavMenuService.toggleMenuItem(menuId,parentId,menu);
    this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);    

  }


  toggleMenuItem(menuId: string) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);
  
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        this.renderer.removeClass(menuItem, 'expanded');
      } else {
        subMenu.classList.add('show');
        this.renderer.addClass(menuItem, 'expanded');
        this.menuId=menuId;
      }
    }
  }
  isExpanded(menuId: string): any {
    // Check if the menu item is expanded
    if(this.sidenavMenuService.expandedItems.findIndex(item => item === menuId)>=0) {
      return true;
    }else{
      return false;
    }
  }

  goToCategory(menu) {

    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);
  this.dialogRef.close();

 }

 cancel() {
    this.dialogRef.close();
}

goToHome(){
  console.log('ss');
  this.router.navigate(['/']);
}

}
